import { useState, useRef, useEffect } from 'react'
import { useRouter } from 'next/router'
import { observer } from 'mobx-react-lite'
import Link from 'next/link'
import { post } from 'lib/common/fetch'
import {
  Button,
  Divider,
  Dropdown,
  IconChevronRight,
  IconMessageCircle,
  IconPlus,
  Typography,
  Listbox,
} from '@supabase/ui'
import { toast } from 'react-hot-toast'
import { Transition } from '@headlessui/react'

import { IS_PLATFORM, API_URL } from 'lib/constants'
import { useStore } from 'hooks'
import SVG from 'react-inlinesvg'
import { PROJECT_STATUS } from 'lib/constants'
import { clickOutsideListener } from 'hooks'

const PageHeader = ({ customHeaderComponents, breadcrumbs = [], headerBorder = true }: any) => {
  const { ui } = useStore()
  const { selectedOrganization, selectedProject } = ui

  return (
    <div className={`p-2 max-h-12 h-12 ${headerBorder ? 'border-b dark:border-dark' : ''}`}>
      <div className="PageHeader">
        <div className="Breadcrumbs flex justify-between">
          <div className="text-sm flex items-center">
            {/* Organization is selected */}
            {selectedOrganization ? (
              <>
                {/* Org Dropdown */}
                <OrgDropdown />

                {/* Project is selected */}
                {selectedProject && (
                  <>
                    <Typography.Text className="mx-2 ">
                      <IconChevronRight size="tiny" strokeWidth={1} />
                    </Typography.Text>
                    {/* Project Dropdown */}
                    <ProjectDropdown />
                  </>
                )}
              </>
            ) : (
              <Typography.Text small className="mx-2">
                <Link as="/" href="/">
                  <a
                    className={`block px-2 py-1 focus:outline-none focus:bg-transparent cursor-pointer dark:hover:text-white`}
                  >
                    Supabase
                  </a>
                </Link>
              </Typography.Text>
            )}
            {/* Additional breadcrumbs are supplied */}
            <BreadcrumbsView defaultValue={breadcrumbs} />
          </div>
          <div className="flex">
            {customHeaderComponents && customHeaderComponents}
            {IS_PLATFORM && <FeedbackDropdown />}
          </div>
        </div>
      </div>
    </div>
  )
}
export default observer(PageHeader)

const BreadcrumbsView = observer(({ defaultValue: breadcrumbs }: any) => {
  return (
    <>
      {breadcrumbs?.length
        ? breadcrumbs.map((x: any) => <BreadcrumbView breadcrumb={x} key={x.key} />)
        : null}
    </>
  )
})

const BreadcrumbView = observer(({ breadcrumb }: any) => {
  return (
    <>
      <Typography.Text type="secondary" className="mx-2 ">
        <IconChevronRight size="small" />
      </Typography.Text>
      <Typography.Text>
        <a
          onClick={breadcrumb.onClick || (() => {})}
          className={`block px-2 py-1 text-sm leading-5 text-gray-400 focus:outline-none focus:bg-gray-100 focus:text-gray-900 ${
            breadcrumb.onClick ? 'cursor-pointer hover:text-white' : ''
          }`}
        >
          {breadcrumb.label}
        </a>
      </Typography.Text>
    </>
  )
})

const OrgDropdown = observer(() => {
  const router = useRouter()
  const { app, ui } = useStore()

  const sortedOrganizations: any[] = app.organizations.list()
  const selectedOrganization: any = ui.selectedOrganization

  return IS_PLATFORM ? (
    <Dropdown
      side="bottom"
      align="start"
      overlay={
        <>
          {sortedOrganizations
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((x) => (
              <Dropdown.Item key={x.slug} onClick={() => router.push(`/org/${x.slug}/settings`)}>
                {x.name}
              </Dropdown.Item>
            ))}
          <Divider light />
          <Dropdown.Item icon={<IconPlus size="tiny" />} onClick={() => router.push(`/new`)}>
            New organization
          </Dropdown.Item>
        </>
      }
    >
      <Button as="span" type="text" size="tiny">
        {selectedOrganization.name}
      </Button>
    </Dropdown>
  ) : (
    <Button as="span" type="text" size="tiny">
      {selectedOrganization.name}
    </Button>
  )
})

const ProjectDropdown = observer(() => {
  const router = useRouter()
  const { app, ui } = useStore()
  const selectedOrganizationProjects = app.projects.list()
  const selectedOrganizationSlug = ui.selectedOrganization?.slug
  const selectedProject: any = ui.selectedProject

  // [Joshen] If let's say we want to support changing projects to retain sub-route
  // const currentSubRoute = router.route.split('/[ref]/')[1] || ''
  // But need to ensure that pages update correctly when the project ref changes

  return IS_PLATFORM ? (
    <Dropdown
      side="bottom"
      align="start"
      overlay={
        <>
          {selectedOrganizationProjects
            .filter((x: any) => x.status !== PROJECT_STATUS.INACTIVE)
            .sort((a: any, b: any) => a.name.localeCompare(b.name))
            .map((x: any) => (
              <Link key={x.ref} href={`/project/${x.ref}`}>
                <a className="block">
                  <Dropdown.Item>{x.name}</Dropdown.Item>
                </a>
              </Link>
            ))}
          <Divider light />
          <Link href={`/new/${selectedOrganizationSlug}`}>
            <a className="block">
              <Dropdown.Item icon={<IconPlus size="tiny" />}>New project</Dropdown.Item>
            </a>
          </Link>
        </>
      }
    >
      <Button as="span" type="text" size="tiny">
        {selectedProject.name}
      </Button>
    </Dropdown>
  ) : (
    <Button as="span" type="text" size="tiny">
      {selectedProject.name}
    </Button>
  )
})

const FeedbackDropdown = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [feedback, setFeedback] = useState('')
  const [category, setCategory] = useState('Feedback')

  const clickContainerRef = clickOutsideListener(() => {
    if (isOpen) setIsOpen(false)
  })

  function onOpen() {
    setIsOpen((isOpen) => !isOpen)
  }

  return (
    <div ref={clickContainerRef} className="relative inline-block text-left mr-1">
      <div>
        <Button onClick={onOpen} type="default" icon={<IconMessageCircle />}>
          Feedback on this page?
        </Button>
      </div>
      <Transition
        show={isOpen}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <FeedbackWidget
          onClose={() => setIsOpen(false)}
          setFeedback={setFeedback}
          feedback={feedback}
          category={category}
          setCategory={setCategory}
        />
      </Transition>
    </div>
  )
}

const FeedbackWidget = ({ onClose, feedback, setFeedback, category, setCategory }: any) => {
  const inputRef = useRef(null)
  const router = useRouter()
  const { ref } = router.query
  const [isSending, setSending] = useState(false)

  useEffect(() => {
    ;(inputRef?.current as any)?.focus()
  }, [inputRef])

  function onFeedbackChange(e: any) {
    setFeedback(e.target.value)
  }

  function onCancel() {
    onClose()
  }

  const sendFeedback = async () => {
    if (feedback.length) {
      setSending(true)
      await post(`${API_URL}/feedback/send`, {
        feedback,
        pathname: router.asPath,
        category,
        projectRef: ref,
        tags: ['dashboard-feedback'],
      })
      setSending(false)
      toast.success(`Feedback sent. Thank you!`)
    }

    return onClose()
  }

  const FEEDBACK_OPTIONS = [
    {
      key: 'Feedback',
      label: 'Feedback',
      text: 'Ideas on how to improve this page',
      icon: 'feedback',
    },
    {
      key: 'Bug',
      label: 'Report bug',
      text: 'Anything not working as it should',
      icon: 'bug',
    },
    {
      key: 'Question',
      label: 'Question',
      text: 'Not sure how to do something?',
      icon: 'question',
    },
    {
      key: 'Feature request',
      label: 'Feature Request',
      text: 'Anything new you would like?',
      icon: 'feature_request',
    },
    {
      key: 'Problem',
      label: 'Help!',
      text: 'Problems with API or instance',
      icon: 'problem',
    },
  ]

  return (
    <div className="absolute origin-top-right right-0 mt-1 w-80 rounded-md shadow-lg z-50">
      <div className="rounded-md border dark:border-dark bg-bg-primary-light dark:bg-bg-primary-dark space-y-3 p-3">
        <Listbox
          onChange={(value: any) => setCategory(value)}
          value={category}
          key="feedback-listbox"
        >
          {FEEDBACK_OPTIONS.map((x: any, i: number) => {
            return (
              <Listbox.Option
                key={`${x.key}-option`}
                label={x.label}
                value={x.key}
                addOnBefore={() => (
                  <div className="bg-bg-alt-dark h-6 w-6 flex items-center justify-center rounded">
                    <SVG
                      src={`/img/feedback/${x.icon}.svg?132=123`}
                      style={{ width: `14px`, height: `14px` }}
                    />
                  </div>
                )}
              >
                {x.label}
                <Typography.Text
                  small
                  type="secondary"
                  className="opacity-50 block"
                  key={`${x.key}-description`}
                >
                  {x.text}
                </Typography.Text>
              </Listbox.Option>
            )
          })}
        </Listbox>
        <div>
          <textarea
            placeholder={FEEDBACK_OPTIONS.find((x) => x.key == category)?.text}
            rows={4}
            ref={inputRef}
            value={feedback}
            onChange={onFeedbackChange}
          />
        </div>
        <div className="flex justify-end space-x-2">
          <Button type="default" onClick={onCancel} className="hover:border-gray-500">
            Cancel
          </Button>
          <Button disabled={isSending} loading={isSending} onClick={sendFeedback}>
            Send
          </Button>
        </div>
      </div>
    </div>
  )
}
